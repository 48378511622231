import React from 'react'
import get from 'lodash/get'
import styled from '@emotion/styled'
import { RichText } from 'prismic-reactjs'

import Layout from '../components/layout'
import ProductSlider from '../components/product-slider'
import { rhythm } from '../utils/typography'
import { linkResolver } from '../utils/link-resolver'
import htmlSerializer from '../utils/html-serializer'

const Products = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const Content = styled.div`
  text-align: justify;
  margin-bottom: ${rhythm(2)};
  font-size: ${rhythm(0.7)};
`

function Shop({ data }) {
  const {
    meta_title,
    meta_description,
    meta_keywords,
    page_subtitle,
    content_body,
  } = get(data, 'prismic.allShops.edges[0].node')
  const etsyProducts = get(data, 'allFeaturedEtsyListing.edges')

  const productsWithImages = etsyProducts.map(({ node }) => {
    return {
      title: node.title,
      url: node.url,
      tags: node.tags,
      fluid: get(
        node,
        'childrenEtsyListingImage.[0].childFile.childImageSharp.fluid'
      ),
      photos: get(node, 'childrenEtsyListingImage').map(
        i => i.childFile.childImageSharp.fluid
      ),
    }
  })

  return (
    <Layout
      title={RichText.asText(meta_title)}
      subtitle={RichText.asText(page_subtitle)}
      site={data.site}
      customKeywords={RichText.asText(meta_keywords)}
      customDescription={RichText.asText(meta_description)}
    >
      <Content>
        <RichText
            render={content_body}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializer}
        />
      </Content>
      <Products>
        {productsWithImages.map((product, id) => {
          return <ProductSlider key={id} product={product} />
        })}
      </Products>
    </Layout>
  )
}

export default Shop
