import React from 'react'
import Slider from 'react-slick'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { TiArrowLeftOutline, TiArrowRightOutline } from 'react-icons/ti'

import { rhythm } from '../utils/typography'

const ProductContainer = styled.div`
  width: ${rhythm(15)};
  height: ${rhythm(15)};
  margin-bottom: ${rhythm(4)};

  .slick-slider {
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
    padding: ${rhythm(1)};
    width: ${rhythm(15)};
    height: ${rhythm(15)};
  }
`
const ProductTitle = styled.h4`
  text-align: center;
  height: ${rhythm(1.5)};
`
const ProductImageContainer = styled.div``
const ProductImage = styled(Img)`
  img {
    margin: 0;
  }
`
const ProductLink = styled.a``

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <TiArrowRightOutline
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      color="black"
    />
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <TiArrowLeftOutline
      className={className}
      color="black"
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  )
}

export default function ProductSlider({ product: { title, photos, url } }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  return (
    <ProductContainer>
      <ProductTitle dangerouslySetInnerHTML={{ __html: title }} />
      <Slider {...settings}>
        {photos.map((p, i) => {
          return (
            <ProductImageContainer key={i}>
              <ProductLink href={url} target="_blank" rel="noopener">
                <ProductImage fluid={p} />
              </ProductLink>
            </ProductImageContainer>
          )
        })}
      </Slider>
    </ProductContainer>
  )
}
