import React from 'react'
import { graphql } from 'gatsby'

import Shop from './_shop'

export default props => <Shop {...props} />

export const pageQuery = graphql`
  query {
    site {
      ...SiteInformation
    }
    prismic {
      allShops(lang: "en-gb") {
        edges {
          node {
            meta_title
            meta_keywords
            meta_description
            page_subtitle
            content_body
            _meta {
              id
            }
          }
        }
      }
    }
    allFeaturedEtsyListing {
      edges {
        node {
          id
          title
          tags
          url
          childrenEtsyListingImage {
            rank
            childFile {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 300) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  }
`
